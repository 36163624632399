.cases {
    margin-top: -140px !important;
    width: 60% !important;
}
.cases {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1000px;
    padding: 40px 30px 60px 30px;
    text-align: center;

    .cases__title {
        margin-bottom: 60px;
    }

    .cases__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
        grid-gap: 30px;
        min-height: 100%;

        > li {
            justify-content: center;
            display: flex;


            &:nth-child(even) {
                position: relative;
                top: 50px;
            }

            &:nth-last-child(1):nth-child(odd) {
                left: -195px;
                position: relative;
            }
        }

        .case-card {
            -webkit-box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.2);
            // height: 500px;
            background-color: $baseWhite;
            border-radius: 6px;
            box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.2);
            display: flex;
            flex-direction: column;
            overflow: hidden;
            padding: 20px 20px 0px 20px;
            position: relative;
            text-decoration: none;
            transition: all 0.3s;
            width: 320px;

            &:nth-of-type(2) {
                top: 30px;
            }

            &__body {
                height: 100%;

                &-partner {
                    align-items: center;
                    color: $baseGreen-secondary;
                    display: flex;
                    font-size: 12px;
                    font-weight: 600;
                    height: 30px;
                    justify-content: center;
                    margin: 20px auto;
                    text-decoration: none;
                    text-transform: uppercase;
                    width: 80%;
                }

                &-title {
                    color: $baseGreen-primary;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 32px;
                    margin: 20px auto;
                    width: 90%;
                }

                &-labels {
                    display: flex;
                    justify-content: center;
                    margin: 0px;
                    min-height: 30px;
                    padding: 0px;
                    flex-wrap: wrap;
                    margin-top: 20px;
    
                    li {
                        align-items: center;
                        background-color: $baseGreen-primary;
                        border-radius: 6px;
                        color: $baseWhite;
                        display: flex;
                        font-size: 10px;
                        font-weight: 300;
                        justify-content: center;
                        padding: 8px 10px;
                        text-decoration: none;
                        text-transform: lowercase;
                        margin-left: 8px;
                        margin-bottom: 5px;
                    }
                }
            }

            &__footer {
                display: flex;
                margin-top: auto;
                position: relative;
                width: 100%;

                img {
                    margin-top: 40px;
                    width: 100%;
                }
            }
        }
    }
    .case__btn {
        margin-top: 50px;
        align-self: center;
        display: none;
    }
}

@media screen and (max-width: 1250px) {
    .cases {
        width: 90% !important;
    }
}

@media screen and (max-width: 833px) {
    .cases {
        width: 85% !important;
    }

    .cases .cases__wrapper {
        gap: 0;
        grid-gap: 0px;
    }

    .cases .cases__wrapper > li {
        margin-bottom: 30px;

        &:nth-last-child(1):nth-child(odd) {
            left: 0;
            position: relative;
        }
    }

    .cases .cases__wrapper > li:nth-child(even) {
        top: inherit;
    }

   .cases {
        padding: 40px 8.33% 60px 8.33%;
    }
}

@media screen and (max-width: 500px) {
    .case-card {
        width: 100% !important;
    }
}