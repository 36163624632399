/* Generated by Glyphter (http://www.glyphter.com) on  Sun Feb 27 2022*/
@font-face {
  font-family: "oetang";
  src: url("../assets/fonts/glyphter/oetang.eot");
  src: url("../assets/fonts/glyphter/oetang.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/glyphter/oetang.woff") format("woff"),
    url("../assets/fonts/glyphter/oetang.ttf") format("truetype"),
    url("../assets/fonts/glyphter/oetang.svg#oetang") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class*="icon-"]:before {
  display: inline-block;
  font-family: "oetang";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-contact:before {
  content: "\0042";
}
.icon-arrow-left:before {
  content: "\0046";
}
.icon-arrow-right:before {
  content: "\0047";
}
.icon-arrow-down:before {
  content: "\0048";
}
.icon-external:before {
  content: "\0049";
}
.icon-arrow-up:before {
  content: "\004a";
}
.icon-download:before {
  content: "\004b";
}
.icon-facebook:before {
  content: "\004c";
}
.icon-linkedin:before {
  content: "\004d";
}
.icon-instagram:before {
  content: "\004e";
}
.icon-twitter:before {
  content: "\004f";
}
.icon-minus-square:before {
  content: "\0050";
}
.icon-minus:before {
  content: "\0051";
}
.icon-plus-square:before {
  content: "\0052";
}
.icon-plus:before {
  content: "\0053";
}
.icon-youtube:before {
  content: "\0054";
}
.icon-arrow-full-down:before {
  content: "\0055";
}
.icon-arrow-full-up:before {
  content: "\0056";
}
.icon-arrow-full-left:before {
  content: "\0057";
}
.icon-arrow-full-right:before {
  content: "\0058";
}
