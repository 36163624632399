.service-quick-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    max-width: 1400px;
    padding: 40px 8.33% 60px 8.33%;

    .service-quick-nav__title {
        font-weight: 500;
        line-height: 56px;
        margin-bottom: 70px;
        text-align: center;

        span {
            color: $baseGreen-secondary;
        }
    }

    .service-quick-nav__wrapper {
        display: grid;
        gap: 15px 15px;
        grid-gap: 15px 15px;
        grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
        justify-content: center;

        .service-quick-card {
            align-items: center;
            background-color: $baseBeige;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            justify-content: baseline;
            margin-top: 40%;
            max-height: 250px;
            min-height: 150px;
            position: relative;
            transition: all 0.3s;

            &:hover,
            &:visited {
                color: $baseGreen-primary !important;
                transition: all 0.3s;

                img {
                    transform: scale(1.1);
                    transition: all 0.3s;
                }
            }

            .service-quick-card__icon {
                margin: auto;
                position: absolute;
                text-align: center;
                top: -100px;
                width: 240px;
                width: 90%;

                img {
                    transition: all 0.3s;
                    width: 80%;
                }
            }
            .service-quick-card__servicetitle {
                margin-bottom: 5%;
                margin-top: 40%;
                text-align: center;
                width: 80%;

                h3 {
                    align-self: center;
                    font-size: 25px;
                    font-weight: 400;
                    text-decoration: underline;
                    transition: all 0.3s;
                }
            }
        }
    }

    .service-quick-card--current {
        filter: opacity(40%);
    }

    .service-quick-card--onderzoek:hover {
        background-color: $researchBlue-tertiary;
        filter: opacity(100%);
    }
    .service-quick-card--strategie:hover {
        background-color: $strategyPink-tertiary;
        filter: opacity(100%);
    }
    .service-quick-card--product-design:hover {
        background-color: $productDesignYellow-tertiary;
        filter: opacity(100%);
    }
    .service-quick-card--content-creatie:hover {
        background-color: $contentCreationTeal-tertiary;
        filter: opacity(100%);
    }
    .service-quick-card--begeleiding:hover {
        background-color: $guidanceRed-tertiary;
        filter: opacity(100%);
    }

    @media screen and (min-width: $breakpoint-l) {
        .service-quick-card:first-of-type,
        .service-quick-card:last-of-type {
            position: relative;
            bottom: 30px;
        }
    }

    @media screen and (min-width: $breakpoint-xl) {
        .service-quick-card {
            .service-quick-card__servicetitle {
                h3 {
                    font-size: 30px;
                }
            }
        }
    }

    @media screen and (max-width: $breakpoint-m) {
        .service-quick-card {
            margin-top: 25% !important;
            .service-quick-card__icon {
                max-width: 170px;
                top: -60px !important;
            }
            h3 {
                font-size: 24px;
            }
        }
    }
    
    @media screen and (max-width: 450px) {
        padding: 0% 16.66% 8.33% 16.66%;
    }
}
