.cta-banner {
  align-items: center;
  background-attachment: fixed;
  background-color: $baseBeige;
  background-image: url("../../assets/logos/logo_oetang__solo_greensec@2x.png");
  background-position: -40px;
  background-repeat: no-repeat;
  background-size: 475px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin: 0% 8.33%;
  margin: 80px auto 80px auto;
  max-width: 1400px;
  overflow: hidden;
  padding: 60px 0px;
  position: relative;
  width: 83.34vw;

  .cta-banner__title {
    font-size: 30px;
    line-height: 38px;
    padding: 0 30px;
    span {
      display: block;
    }
  }
  .cta-banner__btn {
    align-self: center;
    .short-text {
      display: none;
    }
    @media screen and (max-width: 769px) {
      .long-text {
        display: none;
      }
      .short-text {
        display: inline;
      }
    }
  }

  @media screen and (min-width: 1800px) {
    background-size: 875px !important;
  }
  @media screen and (min-width: 1700px) and (max-width: 1799px) {
    background-position: 6%;
    background-size: 575px;
  }
  @media screen and (min-width: 1600px) and (max-width: 1699px) {
    background-position: 2%;
    background-size: 575px;
  }
  @media screen and (min-width: 1500px) {
    background-position: -2% !important;
    background-size: 575px;
  }
  @media screen and (min-width: $breakpoint-xl) {
    background-position: -90px;
    background-size: 575px;
  }
  @media screen and (max-width: $breakpoint-xl) {
    background-position: -70px;
    background-size: 475px;
  }

  @media screen and (max-width: $breakpoint-l) {
    background-position: -40px;
    background-size: 375px;
    padding: 30px 0px;
  }
  @media screen and (max-width: $breakpoint-m) {
    background-position: -40px;
    background-size: 275px;
    padding: 30px 0px;
  }
  @media screen and (max-width: $breakpoint-s) {
    background-attachment: scroll;
    background-position: -160px -240px;
    background-size: 575px;
    padding-top: 300px !important;
    padding: 30px 0px;
  }
}
