.product-details {
  margin: auto;
  max-width: 900px;
  padding: 80px 8.33% 40px 8.33%;

  &__baseline {
    line-height: 1.2em;
    width: 80%;
  }

  &__baseText {
    column-count: 2;
    column-gap: 50px;
    column-span: all;
    column-width: 250px;
    font-size: 18px;
    line-height: 28px;
    hyphens: none;

    @media screen and (max-width: $breakpoint-s) {
      column-count: 1;
    }

    br {
      line-height: 2em;
    }
  }
}
