.strengths {
    background-color: $baseGreen-primary;
    border-radius: 6px;
    display: none;
    flex-direction: column;
    gap: 15px;
    grid-gap: 15px;
    margin: 100px auto 100px auto;
    max-width: 1400px;
    padding: 8.33% 0% 8.33% 8.33%;
    width: 83.33vw !important;

    &__title {
        color: $baseWhite;
    }

    &__slider {
        -ms-overflow-style: none; /* IE and Edge */
        display: flex;
        flex-wrap: nowrap;
        gap: 15px;
        grid-gap: 15px;
        height: 454px;
        overflow-x: scroll;
        overflow-y: hidden;
        overflow: hidden;
        scroll-behavior: smooth;
        scroll-snap-points-x: repeat(550px);
        scroll-snap-type: mandatory;
        scroll-snap-type: x mandatory;
        scrollbar-width: none; /* Firefox */
        width: 100%;

        &::-webkit-scrollbar {
            display: none;
        }

        .slider-carousel__item,
        .slider-carousel__mock-item {
            -moz-transition: all 0.3s;
            -o-transition: all 0.3s;
            -webkit-transition: all 0.3s;
            border-radius: 6px;
            border: 1px solid;
            filter: opacity(0.6);
            height: 450px;
            scroll-snap-align: start;
            transition: all 0.3s;
            width: 536px;

            &:hover {
                -moz-transition: all 0.3s;
                -o-transition: all 0.3s;
                -webkit-transition: all 0.3s;
                cursor: pointer;
                filter: opacity(1);
                transition: all 0.3s;
            }

            &--active {
                -moz-transition: all 0.3s;
                -o-transition: all 0.3s;
                -webkit-transition: all 0.3s;
                filter: opacity(1);
                transition: all 0.3s;
            }
        }
    }

    &__slider-nav {
        display: flex;
        justify-content: space-between;
        margin-right: 8.33%;

        &-info {
            color: $baseWhite;
            min-width: 300px;
            width: 50%;

            h3 {
                font-size: 30px;
                line-height: 38px;
                margin-top: 50px;
            }

            p {
                color: $baseWhite;
            }
        }

        &-btns {
            display: flex;
            gap: 15px;
            grid-gap: 15px;
            margin-top: 50px;

            .btn-green-on-green {
                align-items: center;
                cursor: pointer;
                display: flex;
                height: 58px;
                justify-content: center;
                padding: 0;
                width: 58px;

                &--inactive {
                    border-color: rgba($baseWhite, 0.2) !important;
                    cursor: default;

                    i {
                        color: rgba($baseWhite, 0.2) !important;
                    }
                }

                i {
                    font-size: 20px;
                    margin: 0px;
                    position: static;

                    &:first-of-type {
                        padding-right: 5px;
                    }
                    &:last-of-type {
                        padding-left: 5px;
                    }
                }
            }
        }
    }
}
