#vacancy {
  .vacancy-hero {
    align-items: center;
    background: $baseGreen-primary;
    color: $baseWhite;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0% 8.33% 8.33% 8.33%;
    position: relative;
    z-index: -1;

    i {
      color: $baseGreen-secondary;
    }

    &:after {
      background-image: url("../../assets/shapes/hero-shape.svg");
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: contain;
      bottom: -2px;
      content: "";
      height: 62px;
      left: 0;
      position: absolute;
      right: 0;
      width: 102%;
    }

    &__type {
      margin-top: 70px;
      color: $baseGreen-secondary;
      font-size: 30px;
      margin-bottom: 40px;

      @media screen {
      }
    }
    &__title {
      margin-bottom: 30px;
      margin-top: 30px;
      text-align: center;
      width: 90%;
    }

    &__subtitle {
      color: $baseGreen-secondary;
      text-align: center;
      margin-top: 0px;
      margin-bottom: 70px;
    }
  }
  .vacancy-details {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 900px;
    padding: 40px 8.33%;

    &__banner {
      margin-top: -180px;
      width: 300px;
    }

    &__info {
      margin-top: 0px;
    }

    &__text {
      display: flex;
      column-gap: 100px;

      p {
        flex-basis: 50%;
        margin-top: 0;
        hyphens: none;
      }
    }

    h3 {
      color: $baseGreen-secondary;
      font-size: 30px;
      margin-top: 50px;
      & + p {
        margin-bottom: 15px;
      }
      //   & + ul {
      //     margin-top: 40px;
      //   }
    }
    h4 {
      margin-bottom: 20px;
      font-family: "Lexend";
      font-size: 24px;
      font-weight: 400;
    }

    p > strong,
    li > strong {
      font-weight: 600;
    }

    ul {
      margin-top: 0px;
      color: $baseGray;
      li {
        position: relative;
        &:before {
          content: "•";
          font-size: 40px;
          position: absolute;
          top: -1px;
          left: 0px;
          color: $baseGreen-secondary;
        }
        padding-left: 25px;
        font-weight: 300;
        font-size: 16px;
        font-family: "Lexend";
        margin-bottom: 12px;
        line-height: 24px;
      }
    }

    &__wrapper {
      div {
        img {
          width: 100%;
        }
      }
    }
  }

  .vacancy-wrapper {
    background: $baseGreen-primary;
    border-radius: 8px;
    color: $baseWhite;
    column-gap: 100px;
    display: flex;
    margin: auto;
    max-width: 1400px;
    padding: 80px 80px 140px 80px;

    > div {
      flex-basis: 50%;
    }

    h2,
    h3 {
      margin-top: 0;
    }
    h3 {
      font-size: 26px;
    }

    p {
      color: $baseWhite;

      a {
        color: $baseWhite;
      }
    }

    .btn-green {
      margin: 10px 15px 10px 0;

      &:hover {
        background: lighten($baseGreen-primary, 10%);
      }
    }
  }

  @media screen and (max-width: $breakpoint-m) {
    .vacancy-hero {
      &__type {
        font-size: 25px;
        margin-bottom: 30px;
      }
    }
    .vacancy-details__text {
      flex-direction: column;
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    .vacancy-hero {
      &__type {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }

  .cta-banner {
    margin-top: 0px;
    margin-bottom: 120px;
  }
}
