.linkedCases {
    max-width: 1400px !important;
}

.linkedCases {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1000px;
    padding: 40px 30px 60px 30px;
    text-align: center;

    .linkedCases__title {
        margin-bottom: 60px;
    }

    .linkedCases__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
        grid-gap: 30px;
        min-height: 100%;

        > li {
            display: flex;

            &:nth-child(even) {
                position: relative;
                top: 50px;
            }

            &:nth-last-child(1):nth-child(odd) {
                left: -195px;
                position: relative;
            }
        }

        .case-card {
            -webkit-box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.105);
            // height: 500px;
            background-color: $baseWhite;
            border-radius: 6px;
            box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.105);
            display: flex;
            flex-direction: column;
            overflow: hidden;
            padding: 20px 20px 0px 20px;
            position: relative;
            text-decoration: none;
            transition: all 0.3s;
            width: 320px;

            &:nth-of-type(2) {
                top: 30px;
            }

            &__header {
                display: flex;
                justify-content: flex-end;
                margin: 0px;
                min-height: 30px;
                padding: 0px;
                flex-wrap: wrap;

                li {
                    align-items: center;
                    background-color: $baseGreen-primary;
                    border-radius: 6px;
                    color: $baseWhite;
                    display: flex;
                    font-size: 10px;
                    font-weight: 300;
                    justify-content: center;
                    padding: 8px 10px;
                    text-decoration: none;
                    text-transform: lowercase;
                    margin-left: 8px;
                    margin-bottom: 5px;
                }
            }

            &__body {
                height: 100%;

                &-partner {
                    align-items: center;
                    color: $baseGreen-secondary;
                    display: flex;
                    font-size: 12px;
                    font-weight: 600;
                    height: 30px;
                    justify-content: center;
                    margin: 20px auto;
                    text-decoration: none;
                    text-transform: uppercase;
                    width: 80%;
                }

                &-title {
                    color: $baseGreen-primary;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 32px;
                    margin: 20px auto;
                    width: 90%;
                }
            }

            &__footer {
                display: flex;
                margin-top: auto;
                position: relative;
                width: 100%;

                img {
                    margin-top: 40px;
                    width: 100%;
                }
            }
        }
    }

    .linkedCases__btn {
        margin-top: 50px;
        align-self: center;
        display: none;
    }
    @media screen and (max-width: 1115px) {
        .case-card:nth-of-type(2) {
            top: 0px !important;
        }
        // .case-card__footer {
        //   display: none;
        // }
    }
}

@media screen and (max-width: 833px) {
    .linkedCases, .cases {
        padding: 40px 8.33% 60px 8.33%;
    }
}