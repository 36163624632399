.service-proces {
    margin: 40px auto;
    max-width: 1400px;
    width: 83.34vw;

    .service-proces__title {
        text-align: center;

        span {
            color: $baseGreen-secondary;
        }
    }
    .service-proces__wrapper {
        display: grid;
        gap: 15px 15px;
        grid-gap: 15px 15px;
        grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
        justify-content: center;
        width: 100%;

        .service-proces-card {
            background-color: $baseGreen-primary;
            border-radius: 6px;
            padding: 60px 60px;

            .service-proces-card__header {
                h3 {
                    color: $baseGreen-secondary;
                    font-size: 30px;
                    font-weight: 400;
                    margin-top: 0px;
                }
            }

            .service-proces-card__body {
                p {
                    margin-bottom: 0px;
                    color: white;
                    line-height: 28px;
                }
            }
        }
    }

    @media screen and (max-width: $breakpoint-l) {
        .service-proces__wrapper {
            .service-proces-card {
                padding: 40px;
                .service-proces-card__header {
                    h3 {
                        font-size: 26px;
                    }
                }
                .service-proces-card__body {
                    p {
                        line-height: 26px;
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: $breakpoint-m) {
        .service-proces__wrapper {
            grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
            .service-proces-card {
                padding: 40px;
                .service-proces-card__header {
                    h3 {
                        font-size: 26px;
                    }
                }
            }
        }
    }
}
