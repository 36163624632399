.header {
  align-items: center;
  background: $baseBeige;
  display: flex;
  justify-content: space-between;
  padding: 30px 8.33% 30px 8.33%;
  &-menu__main-nav-item {
    &--active {
      a {
        color: $baseGreen-secondary !important;
        &:before {
          background-color: $baseGreen-secondary !important;
          transition: none;
          transform: none;
        }
      }
    }
  }
  .logo {
    align-items: center;
    display: flex;

    &__aap {
      height: 50px;
    }

    &__title {
      background: url("../../assets/logos/logo-oetang-dgroen.svg") center center
        no-repeat;
      height: 35px;
      margin-left: 20px;
      position: relative;
      top: 3px;
      width: 130px;
    }
  }

  &--green {
    background: $baseGreen-primary;

    .logo {
      &__title {
        background: url("../../assets/logos/logo-oetang-wit.svg") center center
          no-repeat;
      }
    }

    .header-menu__main-nav-item {
      a {
        color: $baseWhite;
        position: relative;
        z-index: 4 !important;
        &:before {
          background-color: $baseWhite;
        }
      }
    }

    .hamburger span {
      background: $baseWhite;
    }
  }

  &--lightblue {
    background: $contentCreationTeal-tertiary;
  }
}

@media screen and (max-width: 1024px) {
  .logo__title {
    display: none;
  }
}
