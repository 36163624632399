.motto {
    background-color: $baseWhite;
    display: flex;
    justify-content: center;
    padding: 80px 8.33% 15px 8.33%!important;
    position: relative;
    z-index: 1;

    .motto__title {
        font-size: 44px;
        line-height: 56px;
        max-width: 930px;
        text-align: center;

        span {
        color: $baseGreen-secondary;
        }
    }
}

@media screen and (max-width: $breakpoint-m) {
  .motto__title {
    font-size: 28px;
    line-height: 36px;
    width: 100%;
  }
}
