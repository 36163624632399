.over-ons-quicknav {
    align-items: center;
    background-color: $baseGreen-primary;
    border-radius: 6px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 15px;
    grid-gap: 15px;
    margin: 100px auto 100px auto;
    max-width: 1400px;
    padding: 8.33%;
    text-align: center;
    width: 83.33vw !important;

    &__title {
        color: $baseWhite;
        margin-bottom: 0px;
        margin-top: 0px;
    }

    &__lead {
        color: $baseWhite;
        margin-bottom: 5%;
        max-width: 750px;

        @media screen and (min-width: $breakpoint-l) {
            font-size: 28px;
            line-height: 1.3em;
        }
    }

    &__btn {
        align-self: center;
        border: 1px solid $baseGreen-secondary;
        
        &:hover {
            border: 1px solid $baseWhite;
            i {
                color: $baseWhite;
            }
        }
    }
}
