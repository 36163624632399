.product-quicknav {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1400px;
    padding: 40px 8.33%;

    &__title {
        font-weight: 500;
        line-height: 56px;
        text-align: center;

        span {
            color: $baseGreen-secondary;
        }
    }

    &__wrapper {
        display: grid;
        gap: 15px 15px;
        grid-gap: 15px 15px;
        grid-template-columns: repeat(4, minmax(150px, 1fr));
        margin-top: 50px;
        width: 100%;

        @media screen and (max-width: $breakpoint-s) {
            grid-template-columns: repeat(2, minmax(150px, 1fr));
        }

        .product-card {
            -moz-transition: all 0.3s;
            -o-transition: all 0.3s;
            -webkit-box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.105);
            -webkit-transition: all 0.3s;
            background-color: white;
            border-radius: 6px;
            box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.105);
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 40px;
            text-decoration: none;
            transition: all 0.3s;

            &:hover {
                -moz-transition: all 0.3s;
                -o-transition: all 0.3s;
                -webkit-box-shadow: 20px 30px 40px rgba(0, 0, 0, 0.201);
                -webkit-transition: all 0.3s;
                box-shadow: 20px 30px 40px rgba(0, 0, 0, 0.201);
                margin-bottom: 5px;
                margin-top: -5px;
                transition: all 0.3s;
            }

            &--current {
                filter: opacity(40%);

                &:hover {
                    filter: opacity(60%);
                    margin: 0px;
                }
            }

            @media screen and (max-width: $breakpoint-l) {
                padding: 25px 30px;
            }

            &__header {
                img {
                    width: 60px;
                }
            }

            &__body {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: flex-start;

                h3 {
                    color: $baseGreen-secondary;
                    font-size: 30px;
                    line-height: 38px;
                    margin-bottom: 0px;

                    @media screen and (max-width: 1420px) {
                        font-size: 24px;
                        line-height: 32px;
                    }

                    @media screen and (max-width: $breakpoint-l) {
                        font-size: 18px;
                        line-height: 24px;
                    }
                }

                p {
                    font-weight: 300;
                    margin-bottom: 0px;

                    @media screen and (max-width: $breakpoint-l) {
                        display: none;
                    }

                    @media screen and (max-width: $breakpoint-s) and (min-width: $breakpoint-xs) {
                        display: inline;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }

            &__footer {
                color: $baseGreen-secondary;
                margin-top: 30px;
                text-decoration: underline;

                @media screen and (max-width: $breakpoint-l) {
                    margin-top: 15px;
                    span:last-of-type {
                        display: none;
                    }
                }

                @media screen and (max-width: $breakpoint-s) and (min-width: $breakpoint-xs) {
                    display: inline;
                    font-size: 14px;
                    line-height: 20px;

                    span {
                        top: -1px;
                    }
                    
                    span:last-of-type {
                        display: inline;
                        margin-left: -10px;
                    }
                }
            }
        }
    }
}
