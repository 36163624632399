.about-us {
  align-items: center;
  background: $baseGreen-primary;
  color: $baseWhite;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0% 8.33% 8.33% 8.33%;
  position: relative;
  z-index: -1;

  i {
    color: $baseGreen-secondary;
  }

  &:after {
    background-image: url("../../assets/shapes/hero-shape.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -2px;
    content: "";
    height: 62px;
    left: 0;
    position: absolute;
    right: 0;
    width: 103%;
  }

  &__title {
    margin-bottom: 100px;
    margin-top: 100px;
    text-align: center;
    width: 80%;
  }

  &-details {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 900px;
    padding: 40px 8.33%;

    &__banner {
      margin-top: -180px;
      width: 300px;
    }

    &__info {
      margin-top: 0px;
    }

    &__text {
      display: flex;
      column-gap: 100px;

      p {
        flex-basis: 50%;
        margin-top: 0;
        hyphens: none;
      }
    }

    &__wrapper {
      div {
        img {
          width: 100%;
        }
      }
    }
  }
}

.team {
  margin: auto;
  max-width: 1400px;
  padding: 40px 8.33%;
}

.team-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  .team-member {
    align-items: center;
    display: flex;
    flex-basis: calc(33.333% - 30px);
    flex-direction: column;
    margin-bottom: 15px;
    border-radius: 6px;
    box-shadow: 10px 20px 30px #0000000D;
    justify-content: flex-start;

    &__info {
        padding: 30px;
        width: 100%;
        position: relative;
    }

    &__linkedin {
        border-radius: 50%;
        position: absolute;
        width: 48px;
        left: calc(50% - 24px);
        top: -30px;
        transform: scale(1);
        transition: transform .3s;
        
        
        &:hover {
            transform: scale(1.05);
            transition: transform .3s;
        }

        img {
            border-radius: 50%;
            width: 100%;
            -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.24);
            -moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.24);
            box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.24);
        }
    }

    &__name {
      font-size: 40px;
      margin-top: 10px;
      font-weight: 600;
      text-align: center;

      span {
        font-size: 18px;
        font-weight: 300;
        display: block;
      }

      &--small {
        font-size: 30px;
        margin-top: 30px;
        font-weight: 500;
      }
    }

    &__function {
      color: $baseGreen-secondary;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.6px;
      padding: 14px 30px 10px 30px;
      text-align: center;
      text-transform: uppercase;
    }

    &__email {
      color: $baseGreen-secondary;
      font-size: 26px;
      text-decoration: none;
      transform: all 0.3s;

      &:hover {
        color: $baseGreen-primary;
        transform: all 0.3s;
      }
    }

    &__pic {
      display: block;

      img {
        width: 100%;
      }
    }
  }
}

.team-believe {
  align-items: center;
  column-gap: 100px;
  display: flex;
  margin: auto;
  max-width: 1400px;
  padding: 80px 8.33% 40px 8.33%;

  > div {
    flex-basis: 50%;
  }

  &__img {
    img {
      width: 100%;
    }
  }
}

.team-who-else {
  margin: auto;
  max-width: 1400px;
  padding: 120px 8.33% 200px 8.33%;

  &__wrapper {
    background: $baseGreen-primary;
    border-radius: 8px;
    color: $baseWhite;
    padding: 80px 80px 40px 80px;

    h2 {
      margin-top: 0;
    }

    p {
      color: $baseWhite;
    }
  }

  &__text {
    display: flex;
    column-gap: 80px;

    > div {
      flex-basis: 50%;
    }

    p {
      margin-top: 0;
    }

    a {
      color: $baseWhite;
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;

    .btn-green {
      margin: 10px 15px 10px 0;

      &:hover {
        background: lighten($baseGreen-primary, 10%);
      }
    }
  }
}

@media screen and (max-width: $breakpoint-l) {
  .team-wrapper {
    .team-member {
      &__name {
        span {
          display: block;
        }
      }
    }

    .about-us__title {
      width: 100%;
    }
  }

  @media screen and (max-width:1103px) {
    .team-wrapper {
        .team-member {
          flex-basis: calc(50% - 15px);
          justify-content: flex-start;
        }
      }
  }

  @media screen and (max-width: $breakpoint-m) {
    .about-us-details__text {
      flex-direction: column;
    }

    .team-who-else__wrapper {
      padding: 80px 40px;
    }

    .team-wrapper {
      .team-member {
        flex-basis: 100%;
        justify-content: flex-start;
      }
    }
  }

  .team-believe {
    flex-direction: column-reverse;

    > div {
      flex-basis: 100%;
    }
  }

  .team-who-else {
    &__text {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .team-wrapper {
    .team-member {
      flex-basis: 100%;
    }
  }
}
