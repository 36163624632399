// BREAKPOINTS
$breakpoint-xs: 576px;
$breakpoint-s: 769px;
$breakpoint-m: 992px;
$breakpoint-l: 1200px;
$breakpoint-xl: 1400px;

// FONTS

// COLORS

// Base
$baseGreen-primary: #004c3f;
$baseGreen-secondary: #00b786;
$baseGreen-tertiary: #dff6ef;
$baseGray: #667e76;
$baseBeige: #fbf7ed;
$baseWhite: #ffffff;
$baseRed-primary: #fc5047;
$baseRed-secondary: #ef533b;

// Research
$researchBlue-primary: #516cf4;
$researchBlue-secondary: #a4b2fa;
$researchBlue-tertiary: #f4f5ff;

// Strategie
$strategyPink-primary: #ff9d9d;
$strategyPink-secondary: #fed8d8;
$strategyPink-tertiary: #fff0f0;

// Product design
$productDesignYellow-primary: #fbbb43;
$productDesignYellow-secondary: #fde3af;
$productDesignYellow-tertiary: #fffce6;

// Content creation
$contentCreationTeal-primary: #47ddc6;
$contentCreationTeal-secondary: #abede4;
$contentCreationTeal-tertiary: #def8f5;

// Guidance
$guidanceRed-primary: #fc6473;
$guidanceRed-secondary: #feafb7;
$guidanceRed-tertiary: #ffe0e3;
