.cookie {
  -webkit-box-shadow: -1px 0px 36px 7px rgba(0, 0, 0, 0.36);
  background: #004c3f;
  border-radius: 6px;
  bottom: 30px;
  box-shadow: -1px 0px 36px 7px rgba(0, 0, 0, 0.36);
  color: $baseWhite;
  left: 30px;
  padding: 40px 40px 40px 40px;
  position: fixed;
  width: 400px;
  z-index: 9999;

  h3 {
    margin-top: 0;
  }

  p {
    color: $baseWhite;
  }

  a {
    color: $baseWhite;
  }

  .btn-green {
    margin: 0 15px 10px 0;
    cursor: pointer;

    &:hover {
      background: lighten($baseGreen-primary, 10%);
      padding-right: 28px;
    }
  }
}

.privacy-policy {
  padding: 40px 8.33%;

  &__intro {
    background: $baseGreen-primary;
    border-radius: 8px;
    color: $baseWhite;
    margin-bottom: 50px;
    padding: 80px 80px 40px 80px;

    p {
      color: $baseWhite;
      margin-bottom: 0;
    }

    h3 {
      color: $baseGreen-secondary;
      margin-top: 0;
    }
  }
}

.CookieConsent {
  background: none !important;
}

#rcc-confirm-button {
  background-color: $baseGreen-secondary !important;
  border-radius: 6px !important;
  border: none;
  bottom: 50px;
  color: $baseWhite !important;
  font-size: 16px;
  left: 55px;
  padding: 20px 28px 20px 25px !important;
  position: absolute;
  text-decoration: none;
  transition: all 0.3s;
}

@media screen and (max-width: $breakpoint-m) {
  .privacy-policy {
    &__intro {
      padding: 80px 40px;
    }
  }
}

@media screen and (max-width: $breakpoint-m) {
  .cookie {
    max-width: 245px;
    left: 10px;
    bottom: 10px;
  }

  #rcc-confirm-button {
    left: 35px;
    bottom: 30px;
  }
}
