.service-hero,
.product-hero,
.eduhome-hero,
.workplacehome-hero,
.home-hero,
.contact-hero,
.privacy-hero {
    display: flex;
    flex-direction: column;
    padding: 0% 8.33% 120px 8.33%;
    position: relative;
    &:after {
        background-image: url("../../assets/shapes/hero-shape.svg");
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: contain;
        bottom: -2px;
        content: "";
        height: 62px;
        left: -2px;
        position: absolute;
        right: 0;
        width: 102%;
    }

    &-breadcrumb {
        display: flex;
        font-size: 14px;

        li:first-of-type {
            color: $baseGreen-secondary;
            &:before {
                content: "";
                margin: 0px;
            }
        }
        li:before {
            background-image: "../../assets/";
            content: "\0047";
            font-family: oetang;
            font-size: 6px;
            margin: 0px 8px;
            position: relative;
            top: -1px;
        }
        li > a {
            color: $baseGreen-secondary;
            text-decoration: none;
        }
    }
}

.service-hero,
.product-hero,
.eduhome-hero,
.workplacehome-hero {
    align-items: center;
    &-breadcrumb {
        max-width: 1400px;
        width: 100%;
    }
    &-pagelead {
        max-width: 1400px;
        width: 100%;
        &__info-title {
            margin-bottom: 18px;
        }
    }
}

.eduhome-hero,
.workplacehome-hero {
    background-color: $baseGreen-primary;
    color: $baseWhite;
    p {
        color: $baseWhite;
    }
    &-pagelead__info-btns {
        display: flex;
        gap: 15px;
        grid-gap: 15px;
    }
    &-pagelead {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 80px;
        grid-gap: 80px;
        justify-content: space-between;

        &__info {
            flex: 1;

            &-lead {
                font-size: 18px;
                line-height: 28px;
                max-width: 600px;
            }
        }

        &__aside {
            flex: 1;
            position: relative;
            padding: 80px 0;
            max-width: 600px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
            }

            &:after {
                content: "";
                background-image: url("../../assets/shapes/thin-circle-edu.svg");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                height: 100%;
                width: 110%;
                display: block;
                position: absolute;
            }
        }
    }
}

.home-hero {
    background-color: $baseBeige;
    color: $baseGreen-primary;
    overflow: hidden;
    position: relative;
    p {
        color: $baseGreen-primary;
    }
    i {
        position: absolute;
        bottom: -70px;
    }
    &-pagelead__info-title {
        margin-bottom: 0px;
    }
    &-pagelead__info-lead {
        margin-top: 30px;
        margin-bottom: 50px;
    }
    &-pagelead__info-btns {
        display: flex;
        justify-content: center;
        gap: 15px;
        grid-gap: 15px;

        @media screen and (max-width: $breakpoint-s) {
            flex-direction: column;
            align-items: center;
            width: 100%;
            .home-hero__btn {
                align-self: center;
            }
        }

        .home-hero__btn {
            min-width: 150px !important;
            &:first-of-type:hover {
                padding-right: 40px !important;
                margin-left: 0px;
            }
            &:nth-of-type(2):hover {
                padding-right: 35px !important;
                margin-left: 0px;
            }
        }
    }
    &-pagelead {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        position: relative;
        z-index: 2;

        &__info {
            &-lead {
                font-size: 18px;
                line-height: 28px;
                max-width: 600px;
            }
        }
    }
}

.service-hero {
    background-color: $baseBeige;
    position: relative;
    &-pagelead {
        display: flex;
        flex-direction: row;
        gap: 80px;
        grid-gap: 80px;
        justify-content: space-between;

        &__info {
            width: 50%;

            &-title {
                font-size: 72px;
            }

            &-lead {
                font-size: 18px;
                line-height: 28px;
                max-width: 600px;
            }
        }

        &__aside {
            // position: relative;
            width: 50%;

            > img {
                display: none !important;
            }

            img {
                display: block;
                // width: 33vw;
                width: 500px;
                margin: 0 auto;
                position: absolute;
                bottom: -90px;
                max-width: 550px;
                left: 60vw;
                z-index: 999;
                //   width: 2% ;
                //   position: relative;
                //   top: 55%;
                //   left: -15%;
            }
        }
    }
}

.product-hero {
    background-color: $contentCreationTeal-tertiary;
    &-pagelead {
        display: flex;
        flex-direction: row;
        gap: 80px;
        grid-gap: 80px;
        justify-content: space-between;

        &__info {
            flex: 1;

            &-lead {
                font-size: 18px;
                line-height: 28px;
                max-width: 600px;
            }
        }

        &__aside {
            flex: 1;
            position: relative;
            background-color: white;
            padding: 80px 0;
            max-width: 650px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 30%;
            }

            &:after {
                content: "";
                background-image: url("../../assets/shapes/thin-circle-green.svg");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                height: 120%;
                width: 100%;
                display: block;
                position: absolute;
            }
        }
    }
}

.contact-hero {
    background-color: $baseBeige;
    color: $baseGreen-primary;
    overflow: hidden;
    position: relative;

    &-pagelead {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        position: relative;
        z-index: 2;

        &__info {
            &-title {
                margin: 0;
            }

            &-lead {
                margin: 30px 0 15px 0;
                font-size: 18px;
                line-height: 28px;
                max-width: 600px;
                color: $baseGreen-primary;
            }
        }
    }

    i {
        position: absolute;
        bottom: -50px;
    }
}

.privacy-hero {
    background-color: $baseBeige;
    color: $baseGreen-primary;
    overflow: hidden;
    position: relative;

    &-pagelead {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        position: relative;
        z-index: 2;

        &__info {
            &-title {
                margin: 0;
            }
        }
    }

    i {
        position: absolute;
        bottom: -50px;
    }
}

@media screen and (max-width: 1400px) {
    .product-hero {
        &-pagelead {
            &__aside {
                width: 300px;
                height: 200px;

                &::after {
                    height: 110%;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .workplacehome-hero,
    .eduhome-hero {
        &-pagelead {
            align-items: flex-start;
            flex-direction: column;
            position: relative;

            &__aside {
                display: none;
            }
        }
    }

    .service-hero {
        &-pagelead {
            flex-direction: column;

            &__info {
                width: 100%;
            }

            &__aside {
                border-radius: 50%;
                padding: 0;
                position: absolute;
                bottom: -40px;
                z-index: 999;
                left: calc(50% - 80px);

                img {
                    width: 45px;
                }

                &:after {
                    display: none;
                }
            }
        }
    }

    .product-hero {
        &-pagelead {
            flex-direction: column;

            &__info {
                width: 100%;
            }

            &__aside {
                border-radius: 50%;
                width: 80px;
                height: 80px;
                padding: 0;
                position: absolute;
                bottom: -40px;
                z-index: 999;
                left: calc(50% - 40px);

                img {
                    width: 45px;
                }

                &:after {
                    display: none;
                }
            }
        }
    }
    .service-hero {
        &-pagelead {
            &__aside {
                img {
                    width: 160px;
                    left: 0;
                    bottom: -40px;
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-s) {
    .home-hero-pagelead__info-lead {
        font-size: 16px;
    }
}