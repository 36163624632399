.header-menu {
    z-index: 9997;

    &__main-nav {
        display: flex;
        flex-wrap: nowrap;
        margin: 0;

        &-item {
            align-items: center;
            color: $baseWhite;
            padding: 15px 20px;
            display: flex;
            text-decoration: none;

            a {
                color: $baseGreen-primary;
                text-decoration: none;
                position: relative;
                overflow: hidden;
                padding: 10px 0;

                &:before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: $baseGreen-primary;
                    opacity: 1;
                    transition: opacity 300ms, transform 300ms;
                    transform: translate3d(-101%, 0, 0);
                }

                &:hover,
                &:focus {
                    color: $baseGreen-primary;

                    &:before {
                        transform: translate3d(0, 0, 0);
                    }
                }

                i {
                    position: relative;
                    top: 0px;
                    margin-left: 2px;
                    font-size: 8px;
                }
            }

            .vacancies-count {
                background-color: $baseGreen-secondary;
                border-radius: 100%;
                display: inline;
                width: 15px;
                height: 15px;
                position: relative;
                vertical-align: center;
                text-align: center;
                padding-top: 1px;
                box-sizing: border-box;
                top: -8px;
                font-size: 11px;
                color: white;
            }

            &:hover {
                cursor: pointer;
            }

            &:first-child {
                margin-left: -15px;
            }

            &.react-tabs__tab--selected {
                color: $baseGreen-secondary;
            }

            &:focus {
                outline: none;
            }

            &:last-child {
                display: none;
            }

            &--sidebar {
                a {
                    &:after {
                        color: $baseGreen-secondary;
                        content: "\0048";
                        font-family: "oetang";
                        font-size: 6px;
                        margin-left: 5px;
                        position: relative;
                        top: -1px;
                    }
                }
            }

            &--training {
                &:after {
                    content: "";
                    background: url('../../assets/shapes/icon-external-arrow.svg') center center no-repeat;
                    color: $baseGreen-primary;
                    margin-left: 5px;
                    position: relative;
                    top: 1px;
                    height: 12px;
                    width: 12px;
                }
            }
        }
    }
}

.sidebar-menu {
    -webkit-transform: translateX(0);
    -webkit-transition: 0.3s ease all;
    background: $baseGreen-primary;
    box-shadow: 20px 0px 99px #000000;
    height: 100%;
    overflow: scroll;
    position: fixed;
    right: -769px;
    top: 0;
    transform: translateX(0);
    transition: 0.3s ease all;
    width: 769px;
    z-index: 9999;
    opacity: 0;

    &.open {
        -webkit-transform: translateX(-769px);
        transform: translateX(-769px);
        opacity: 1;
    }

    .logo {
        align-items: center;
        display: flex;

        &__aap {
            height: 50px;
        }

        &__title {
            height: 35px;
            margin-left: 30px;
            position: relative;
            top: 3px;
        }
    }

    .menu {
        color: white;
        padding: 47px 80px 30px 80px;

        &-header {
            align-items: center;
            display: flex;
            justify-content: space-between;
            position: relative;
        }

        .hamburger {
            position: absolute;
            right: -55px;

            span {
                background: $baseWhite;
            }

            input[type="checkbox"] {
                display: none;

                &:checked~span:nth-of-type(1) {
                    transform-origin: 10px -5px;
                }
            }
        }

        &__title {
            color: $baseWhite;
            font-size: 16px;
            font-weight: 400;
            text-decoration: none;

            &:hover {
                color: $baseGreen-secondary;
            }
        }

        &__motto {
            margin: 60px 0;
            font-weight: 400;
            font-size: 24px;
        }

        &__wrapper {
            display: flex;

            .services-nav {
                padding-right: 80px;

                &__title {
                    color: $baseGreen-secondary;
                    font-size: 24px;
                    font-weight: 400;
                    margin-top: 10px;
                    text-decoration: none;

                    &:hover {
                        color: darken($baseGreen-secondary, 10%);
                    }
                }

                &__menu {
                    &-service {
                        &-title {
                            font-family: "Lexend", sans-serif;
                            font-weight: 400;
                            font-size: 24px;
                            display: flex;
                            flex-direction: column;
                            color: $baseWhite;
                            text-decoration: none;
                            padding: 10px 0 10px 40px;
                            transition: all 0.3s;

                            &:hover {
                                color: $baseGreen-secondary;
                                transition: all 0.3s;
                            }
                        }

                        &-baseline {
                            font-size: 16px;
                            color: $baseGray;
                        }

                        &--content-creation {
                            a {
                                position: relative;

                                &:before {
                                    background: url("../../assets/shapes/services/svg_transparant/simple/s_Oetang__serv_content-creation--simple.svg");
                                    content: " ";
                                    height: 24px;
                                    left: 0;
                                    position: absolute;
                                    top: 7px;
                                    width: 24px;
                                }

                                &:hover {
                                    color: #47ddc6;
                                }
                            }
                        }

                        &--implementation {
                            a {
                                position: relative;

                                &:before {
                                    background: url("../../assets/shapes/services/svg_transparant/simple/s_Oetang__serv_implementation--simple.svg");
                                    content: " ";
                                    height: 24px;
                                    left: 0;
                                    position: absolute;
                                    top: 7px;
                                    width: 24px;
                                }

                                &:hover {
                                    color: #fc6473;
                                }
                            }
                        }

                        &--product-design {
                            a {
                                position: relative;

                                &:before {
                                    background: url("../../assets/shapes/services/svg_transparant/simple/s_Oetang__serv_product-design--simple.svg");
                                    content: " ";
                                    height: 24px;
                                    left: 0;
                                    position: absolute;
                                    top: 7px;
                                    width: 24px;
                                }

                                &:hover {
                                    color: #fbbb43;
                                }
                            }
                        }

                        &--research {
                            a {
                                position: relative;

                                &:before {
                                    background: url("../../assets/shapes/services/svg_transparant/simple/s_Oetang__serv_research--simple.svg");
                                    content: " ";
                                    height: 24px;
                                    left: 0;
                                    position: absolute;
                                    top: 7px;
                                    width: 24px;
                                }

                                &:hover {
                                    color: #516cf4;
                                }
                            }
                        }

                        &--strategy {
                            a {
                                position: relative;

                                &:before {
                                    background: url("../../assets/shapes/services/svg_transparant/simple/s_Oetang__serv_strategy--simple.svg");
                                    content: " ";
                                    height: 24px;
                                    left: 0;
                                    position: absolute;
                                    top: 7px;
                                    width: 24px;
                                }

                                &:hover {
                                    color: #ff9d9d;
                                }
                            }
                        }
                    }
                }

                &__jumper {
                    color: $baseGreen-secondary;
                    display: block;
                    margin-top: 30px;
                    transition: all 0.3s;

                    &:hover {
                        color: darken($baseGreen-secondary, 10%);

                        .btn-circle {
                            border-color: darken($baseGreen-secondary, 10%);

                            i {
                                color: darken($baseGreen-secondary, 10%);
                            }
                        }
                    }
                }
            }

            .products-nav {
                &__title {
                    color: $baseGreen-secondary;
                    font-size: 24px;
                    font-weight: 400;
                    margin-top: 10px;
                    text-decoration: none;
                }

                &__menu {
                    &-product {
                        &-title {
                            font-family: "Lexend", sans-serif;
                            font-weight: 400;
                            font-size: 16px;
                            display: flex;
                            flex-direction: column;
                            color: $baseWhite;
                            text-decoration: none;
                            padding: 11px 0;
                            transition: all 0.3s;

                            &:hover {
                                color: $baseGreen-secondary;
                                transition: all 0.3s;
                            }
                        }
                    }
                }

                &__jumper {
                    color: $baseGreen-secondary;
                    display: block;
                    margin-top: 30px;
                    transition: all 0.3s;

                    &:hover {
                        color: darken($baseGreen-secondary, 10%);

                        .btn-circle {
                            border-color: darken($baseGreen-secondary, 10%);

                            i {
                                color: darken($baseGreen-secondary, 10%);
                            }
                        }
                    }
                }
            }
        }
    }
}

.hamburger {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 25px;
    width: 25px;
    justify-content: center;

    span {
        background: $baseGreen-primary;
        height: 2px;
        margin: 2px 0;
        width: 25px;
        transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);

        &:nth-of-type(1) {
            width: 100%;
        }

        &:nth-of-type(2) {
            width: 60%;
        }

        &:nth-of-type(3) {
            width: 80%;
        }
    }

    input[type="checkbox"] {
        display: none;

        &:checked~span:nth-of-type(1) {
            transform-origin: 10px -5px;
            transform: rotatez(45deg) translate(8px, 0px);
        }

        &:checked~span:nth-of-type(2) {
            transform-origin: top;
            transform: rotatez(-45deg);
            width: 100%;
        }

        &:checked~span:nth-of-type(3) {
            transform-origin: bottom;
            transform: translate(30px, -11px) rotatez(45deg);
            width: 0%;
        }
    }
}

.mobile-menu {
    background: $baseGreen-primary;
    box-shadow: -150px 0px 99px #004c3f26;
    color: white;

    .sidebar-menu {
        background: $baseGreen-primary;
        box-shadow: -150px 0px 99px #004c3f26;
        color: white;
        height: 100%;
        width: 100%;
    }

    &-header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    .logo {
        align-items: center;
        display: flex;

        &__aap {
            height: 50px;
        }
    }

    .hamburger {
        position: absolute;
        right: 14px;
        top: 14px;

        span {
            background: $baseWhite;
        }

        input[type="checkbox"] {
            display: none;

            &:checked~span:nth-of-type(1) {
                transform-origin: 10px -5px;
            }
        }
    }

    &__main-nav {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        margin: 0;
        padding: 60px 0;

        &-item {
            font-size: 28px;
            padding: 15px 0;
            position: relative;
            color: $baseWhite;

            a {
                text-decoration: none;
                color: $baseWhite;
            }

            &:hover {
                cursor: pointer;
            }

            &--tab {
                &:after {
                    display: none !important;
                }
            }

            &-sub {
                a {
                    color: $baseGreen-secondary;
                    padding: 10px 0;
                    font-size: 20px;
                }
            }

            .vacancies-count {
                background-color: $baseGreen-secondary;
                border-radius: 100%;
                display: inline;
                width: 23px;
                height: 23px;
                position: absolute;
                vertical-align: center;
                text-align: center;
                padding-top: 1px;
                // padding: 2px 9px 2px 9px;
                margin-left: 0px;
                box-sizing: border-box;
                top: 8px;
                font-size: 18px;
                color: white;
            }

            &--training {
                a {
                    &:hover {
                        color: $baseRed-primary;
                        

                        &:after {
                            background: url('../../assets/shapes/icon-external-arrow-red.svg') center center no-repeat;
                            transition: all .3s;
                        }
                    }
                    &:after {
                        content: "";
                        display: inline-block;
                        background: url('../../assets/shapes/icon-external-arrow.svg') center center no-repeat;
                        color: $baseGreen-primary;
                        margin-left: 10px;
                        height: 14px;
                        width: 14px;
                    }
                }
            }
        }
    }

    &__wrapper {
        display: flex;

        .services-nav {
            padding-right: 80px;
            width: 100%;

            &__title {
                color: $baseGreen-secondary;
                font-size: 24px;
                font-weight: 400;
                margin-top: 10px;
            }

            &__menu {
                &-service {
                    &-title {
                        background: $baseBeige;
                        border-radius: 10px;
                        font-family: "Lexend", sans-serif;
                        font-weight: 400;
                        font-size: 24px;
                        display: flex;
                        flex-direction: column;
                        color: $baseWhite;
                        text-decoration: none;
                        padding: 30px 20px 30px 60px;
                        transition: all 0.3s;
                        margin-bottom: 15px;
                        width: 100%;

                        &:hover {
                            color: $baseGreen-secondary;
                            transition: all 0.3s;
                        }
                    }

                    &-baseline {
                        font-size: 16px;
                        color: $baseGray;
                    }

                    &--content-creation {
                        a {
                            position: relative;

                            &:before {
                                background: url("../../assets/shapes/services/svg_transparant/simple/s_Oetang__serv_content-creation--simple.svg");
                                content: " ";
                                height: 24px;
                                left: 0;
                                position: absolute;
                                top: 7px;
                                width: 24px;
                            }

                            &:hover {
                                color: #47ddc6;
                            }
                        }
                    }

                    &--implementation {
                        a {
                            position: relative;

                            &:before {
                                background: url("../../assets/shapes/services/svg_transparant/simple/s_Oetang__serv_implementation--simple.svg");
                                content: " ";
                                height: 24px;
                                left: 0;
                                position: absolute;
                                top: 7px;
                                width: 24px;
                            }

                            &:hover {
                                color: #fc6473;
                            }
                        }
                    }

                    &--product-design {
                        a {
                            position: relative;

                            &:before {
                                background: url("../../assets/shapes/services/svg_transparant/simple/s_Oetang__serv_product-design--simple.svg");
                                content: " ";
                                height: 24px;
                                left: 0;
                                position: absolute;
                                top: 7px;
                                width: 24px;
                            }

                            &:hover {
                                color: #fbbb43;
                            }
                        }
                    }

                    &--research {
                        a {
                            position: relative;

                            &:before {
                                background: url("../../assets/shapes/services/svg_transparant/simple/s_Oetang__serv_research--simple.svg");
                                content: " ";
                                height: 24px;
                                left: 0;
                                position: absolute;
                                top: 7px;
                                width: 24px;
                            }

                            &:hover {
                                color: #516cf4;
                            }
                        }
                    }

                    &--strategy {
                        a {
                            position: relative;

                            &:before {
                                background: url("../../assets/shapes/services/svg_transparant/simple/s_Oetang__serv_strategy--simple.svg");
                                content: " ";
                                height: 24px;
                                left: 0;
                                position: absolute;
                                top: 7px;
                                width: 24px;
                            }

                            &:hover {
                                color: #ff9d9d;
                            }
                        }
                    }
                }
            }

            &__jumper {
                color: $baseGreen-secondary;
                display: block;
                margin-top: 30px;
                transition: all 0.3s;

                &:hover {
                    color: $baseWhite;
                    transition: all 0.3s;
                }
            }
        }
    }

    .react-tabs__tab-panel {
        &--selected {
            position: absolute;
            top: 200px;
            background: green;
            height: 100%;
        }
    }
}

@media screen and (max-width: 1024px) {
    .header-menu {
        &__main-nav {
            &-item {
                padding: 15px 15px;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .header-menu {
        &__main-nav {
            &-item {
                &:last-child {
                    display: flex;
                }

                &:not(:last-child) {
                    display: none;
                }
            }
        }
    }

    .sidebar-menu {
        -webkit-transform: translateX(100%);
        -webkit-transition: 0.3s ease all;
        height: 100%;
        overflow: scroll;
        position: fixed;
        right: -100%;
        top: 0;
        transform: translateX(0);
        transition: 0.3s ease all;
        width: 100%;
        z-index: 9998;

        &.open {
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
        }

        .menu {
            padding: 30px 8.33%;

            &#edu,
            &#workplace {
                display: none !important;
            }

            &#mobile {
                display: block !important;
            }

            .hamburger {
                right: 10px !important;
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    .mobile-menu {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .mobile-menu {
        // padding: 31px 43px;
    }
}