.footer {
    background-color: $baseBeige;
    margin-top: 80px;
    overflow: hidden;
    padding: 0px 8.33% 8.33% 8.33%;
    position: relative;

    &:before {
        background-image: url("../../assets/shapes/footer-svg.svg");
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        height: 60px;
        left: 0px;
        position: absolute;
        top: 0;
        width: 100vw !important;
    }

    .footer-cta {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;

        .footer-cta__wrapper {
            display: flex;
            flex-direction: column;
            width: 50%;
        }

        .footer-cta__title {
            font-size: 44px;

            span {
                color: $baseGreen-secondary;
            }
        }

        .footer-cta__btn {
            i {
                -moz-transform: rotate(-20deg);
                -ms-transform: rotate(-20deg);
                -webkit-transform: rotate(-20deg);
                display: inline-block;
                transform: rotate(-20deg);
            }

            &:hover {
                i {
                    -moz-transform: rotate(20deg);
                    -ms-transform: rotate(20deg);
                    -webkit-transform: rotate(20deg);
                    transform: rotate(20deg);
                }
            }
        }

        img {
            min-width: 450px;
            position: relative;
            top: -50px;
            width: 50%;
        }
    }

    .footer-info {
        display: flex;
        flex-direction: row;
        font-size: 16px;
        line-height: 36px;
        padding-top: 80px;

        &__tel {
            width: max-content;
        }

        &__contact {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            font-size: 30px;
            gap: 24px;
            grid-gap: 24px;
            width: 50%;
        }

        &__address {
            width: 35%;

            p {
                margin: 0px;
            }
        }

        &__social {
            width: 15%;

            ul.footer-info__social {
                margin: 0px;

                a {
                    text-decoration: none;
                }
            }
        }
    }

    .footer-credit {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        margin-top: 80px;

        &__policy {
            width: 50%;

            a {
                color: #667e76;
                font-weight: 300;
                text-decoration: none;

                &:hover {
                    color: $baseGreen-secondary;
                }
            }
        }

        &__bank {
            margin: 0;
        }
    }

    .footer-cross-reference {
        align-items: center;
        background-color: $baseGreen-primary;
        bottom: 0;
        color: #fff;
        display: flex;
        font-size: 16px;
        height: 60px;
        left: 0;
        padding: 0 8.33%;
        position: absolute;
        width: 100vw;

        @media screen and (max-width: $breakpoint-s) {
            font-size: 14px;
            padding: 15px 8.33%;
            height: auto;
            width: auto;

            small {
                text-align: center;
            }
        }

        a {
            color: $baseWhite;

            &:hover {
                color: $baseBeige !important;
            }
        }

        i {
            margin-left: 5px;
        }
    }

    @media screen and (max-width: $breakpoint-m) {
        img {
            position: absolute !important;
            top: -50px !important;
            z-index: 4 !important;
        }

        .footer-cta {
            display: block;
            padding-top: 370px;

            &__wrapper {
                min-height: 200px;
                width: 80%;
            }

            &_title {
                font-size: 35px;
            }

            &_btn {
                font-size: 14px;
            }
        }

        .footer-credit {
            align-items: flex-start;
            flex-direction: column;
        }

        .footer-credit span {
            display: block;
            font-size: 14px;
            margin-bottom: 20px;
        }

        .footer-credit span:nth-of-type(2) {
            display: none;
        }
    }

    @media screen and (max-width: $breakpoint-s) {
        padding: 0px 8.33% 100px 8.33%;

        .footer-cta {
            &__wrapper {
                width: 100% !important;
            }
        }

        .footer-info {
            flex-direction: column;
            gap: 50px;
            grid-gap: 50px;

            &__contact,
            &__address,
            &__social {
                width: 80%;
            }

            &_contact {
                gap: 8px;
                grid-gap: 8px;
                font-size: 20px;
            }

            &__address,
            &__social {
                font-size: 14px;
            }
        }
    }
}