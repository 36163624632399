.error {
  align-items: center;
  background: $baseGreen-primary;
  color: $baseWhite;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 128px);
  justify-content: flex-start;
  padding-bottom: 30px;
  box-sizing: border-box !important;

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    div {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 30%;
      transform: rotate(-45deg);
      max-width: 200px;
    }
    h1 {
      margin-bottom: 0;
      text-align: center;
    }
    h2 {
      max-width: 80vw;
      text-align: center;
    }

    .btn-green {
      align-self: center;
    }
  }

  @media screen and (max-width: $breakpoint-m) {
    height: calc(100vh - 115px);
  }
  @media screen and (max-width: $breakpoint-s) {
    img {
      width: 50vh;
    }
  }
  @media screen and (max-height: 550px) {
    &__wrapper {
      div {
        flex-direction: row;

        img {
          width: 30vh;
        }
        h1 {
          margin-top: 0px;
          margin-left: 15px;
        }
      }
    }

    min-height: 100vh;
    height: 100%;
  }
}
