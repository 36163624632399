#comingSoon {
    background-color: $baseBeige;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100vw;

    main {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        max-height: 100%;
        overflow-y: hidden;
        text-align: center;

        img {
            height: 300px;
            max-height: 60vh;
            z-index: 1;
        }

        h2 {
            max-width: 90vw;
            width: 600px;
            z-index: 2;

            @media screen and (max-width: 700px) {
                font-size: 30px;
            }
        }
    }

    .serviceShape {
        animation: rotate 30s linear infinite;
        min-width: 50px;
        position: absolute;
        transform-origin: 1% center;
        width: 10vw;
        z-index: 1;
    }

    .shapeResearch {
        left: 30px;
        top: 20vh;
    }

    .shapeStrategy {
        animation: rotate 28s reverse infinite;
        left: 40vw;
        top: -15vh;
    }

    .shapeProductDesign {
        right: -5vw;
        top: 30vh;
    }

    .shapeContentCreation {
        animation: rotate 40s reverse infinite;
        bottom: -10vh;
        right: 20vw;
    }

    .shapeGuidance {
        bottom: 0vh;
        left: 20vw;
    }

    .serviceShape:before {
        animation: rotate 6s linear reverse infinite;
        content: "";
        height: 100%;
        position: absolute;
        width: 100%;
    }
    
    @keyframes rotate {
        50% {
            transform: rotate(-360deg);
        }
    }
}
