.btn-green {
    align-self: flex-start;
    background-color: $baseGreen-secondary;
    border-radius: 6px;
    border: none;
    color: $baseWhite;
    display: inline-block;
    font-size: 16px;
    justify-content: center;
    margin: 0 6px;
    padding: 20px 28px 20px 25px;
    position: relative;
    text-decoration: none;
    transition: all 0.3s;
  
    &:focus {
      color: $baseWhite;
    }
  
    i {
      font-size: 14px;
      margin-left: 8px;
      position: absolute;
      top: 22px;
      transition-delay: 0.3s;
      transition-property: visibility;
      transition: margin 0.3s ease-in-out;
      visibility: hidden;
    }
  
    &--right {
      i {
        top: 26px !important;
        font-size: 8px !important;
      }
    }
  
    &:hover {
      background-color: $baseGreen-primary;
      color: $baseWhite;
      font-size: 16px;
      justify-content: center;
      margin-left: -10px;
      padding-right: 45px;
      padding: 20px 48px 20px 25px;
      position: relative;
      text-decoration: none;
      transition: all 0.3s;
  
      i {
        margin-left: 10px;
        transition: all 0.3s;
        visibility: visible;
      }
    }
    &--404 {
      &:hover {
        border: white solid 1px;
      }
    }
  }
  
  .btn-green-on-green {
    align-self: flex-start;
    border-radius: 6px;
    border: 1px solid $baseWhite;
    color: $baseWhite !important;
    font-size: 16px;
    font-weight: 300;
    justify-content: center;
    padding: 20px 40px 20px 25px;
    position: relative;
    text-decoration: none;
    transition: all 0.3s;
  
    i {
      font-size: 8px;
      margin-left: 8px;
      position: absolute;
      top: 26px;
      transition-delay: 0.3s;
      transition-property: visibility;
      transition: margin 0.3s ease-in-out;
  
      &.icon-arrow-right {
        font-size: 10px;
        top: 25px;
      }
    }
  
    &:hover {
      border-color: $baseGreen-secondary;
      color: $baseGreen-secondary !important;
      transition: all 0.3s;
    }
  }
  
  .btn-circle {
    border-radius: 50%;
    border: 2px solid $baseGreen-secondary;
    display: inline-block;
    height: 24px;
    margin-right: 15px;
    position: relative;
    text-align: center;
    top: -2px;
    width: 24px;
  
    i {
      color: $baseGreen-secondary;
      font-size: 10px;
      left: 1px;
      position: relative;
      top: 2px;
    }
  
    &--red {
      border-color: $baseRed-primary;
      color: $baseRed-primary;
  
      i, ~ span {
          color: $baseRed-primary;
      }
  
      i {
          &:before {
              display: none;
          }
  
          &:after {
              content: "";
              display: inline-block;
              background: url('../assets/shapes/icon-external-arrow-red.svg') center center no-repeat;
              color: $baseGreen-primary;
              margin-left: 5px;
              position: relative;
              left: -3px;
              height: 10px;
              width: 10px;
          }
      }
    }
  }
  
  .btn-cookie-decline {
    display: inline-block;
    cursor: pointer;
    color: $baseGray;
    text-decoration: underline;
    &:focus,
    &:active {
      color: $baseGreen-secondary;
    }
  }
  
  .btn-red {
      align-items: center;
      background-color: $baseRed-primary;
      border-radius: 6px;
      border: 2px solid $baseRed-primary;
      color: $baseWhite;
      display: flex;
      font-size: 16px;
      justify-content: center;
      padding: 20px 5px 20px 25px;
      position: relative;
      text-decoration: none;
      transition: all 0.3s;
      width: fit-content;
      margin-right: 15px;
  
      &--no-icon {
          padding: 20px 25px;
      }
  
      i {
          font-size: 14px;
          transition: all 0.3s;
          display: flex;
          position: relative;
          top: 1px;
          visibility: hidden;
          margin-left: 8px;
          opacity: 0;
          transition: visibility 0s, opacity 0.5s linear;
      }
  
      &:hover {
          background-color: $baseRed-primary;
          color: $baseWhite;
          font-size: 16px;
          justify-content: center;
          padding: 20px 25px 20px 25px;
          position: relative;
          text-decoration: none;
          transition: all 0.3s;
  
          i {
              visibility: visible;
              opacity: 1;
          }
      }
  
      &:focus {
          color: $baseWhite;
      }
  
      &--404 {
          &:hover {
              border: white solid 1px;
          }
      }
  
      &--inverted {
          border: 2px solid $baseRed-primary;
          background: $baseWhite;
          color: $baseRed-primary;
  
          &:hover {
              background: $baseWhite;
              color: $baseRed-primary;
          }
      }
  }