#contact {
    padding: 120px 8.33% 40px 8.33%;

    .contact-wrapper {
        background: $baseGreen-primary;
        border-radius: 8px;
        color: $baseWhite;
        column-gap: 100px;
        display: flex;
        margin: auto;
        max-width: 1400px;
        padding: 80px 80px 140px 80px;

        > div {
            flex-basis: 50%;
        }

        h2,
        h3 {
            margin-top: 0;
        }
        h3 {
            font-size: 26px;

            span.red {
                color: $baseRed-primary;
            }
        }

        p {
            color: $baseWhite;

            a {
                color: $baseWhite;
            }
        }

        .btn-green {
            margin: 10px 15px 10px 0;

            &:hover {
                background: lighten($baseGreen-primary, 10%);
            }
        }
    }

    .contact__form {
        display: flex;
        flex-direction: column;

        label {
            color: $baseGreen-primary;
            position: absolute;
            top: -15px;
        }

        &-input {
            display: flex;
            position: relative;
        }

        input,
        textarea {
            border-bottom: 2px solid #667e76;
            border-radius: 6px;
            border: none;
            margin: 10px 0 30px 0;
            padding: 20px 15px;
            width: 100%;

            &:focus {
                border-bottom: 2px solid $baseGreen-secondary;
                outline: none;

                ~ label {
                    color: $baseGreen-secondary;
                }
            }
        }

        button {
            &:hover {
                cursor: pointer;
            }
        }

        .btn-green {
            margin-bottom: 15px;

            &:hover {
                padding-right: 28px;
            }
        }

        span {
            display: none;

            &.visible {
                display: block;
            }
        }
    }

    .contact-company {
        color: #004c3f;
        display: block;
        font-size: 18px;
        line-height: 28px;
        margin: 80px 0 15px 0;
        text-align: center;
        width: 100%;
    }
}

.divider-line {
    margin: 40px 0;
    border-top: 1px solid #667E76;
}

@media screen and (max-width: $breakpoint-l) {
    .contact-wrapper {
        flex-direction: column;

        > div:first-of-type {
            margin-bottom: 50px;
        }
    }
}

@media screen and (max-width: $breakpoint-m) {
    #contact {
        .contact-wrapper {
            padding: 80px 40px 140px 40px;
        }
    }
}