@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./normalize.scss";
@import "./variables.scss";
@import "./btns.scss";

@import "./oetang-glyphter.css";
@import "./pages.scss";
@import "./components.scss";

#root {
    overflow: hidden;
}

body {
  font-family: "Lexend", sans-serif;
  color: $baseGreen-primary;
  box-sizing: border-box;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

  h1 {
    font-weight: 500;
    font-size: 72px;
    line-height: 80px;
    span {
      color: $baseGreen-secondary;
    }
  }
  h2 {
    font-weight: 500;
    font-size: 44px;
    span {
      color: $baseGreen-secondary;
    }
  }
  h3 {
    font-weight: 500;

    span {
      color: $baseGreen-secondary;
    }
  }
  ul {
    padding-left: 0px;
    list-style-type: none;
  }
  a {
    color: $baseGreen-primary;
    &:hover,
    &:focus {
      color: $baseGreen-secondary;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      -ms-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s;
      cursor: pointer;
    }
  }
  p {
    line-height: 24px;
    font-size: 16px;
    color: $baseGray;
    font-weight: 300;
    margin-bottom: 30px;
  }
  .d-none {
    display: none;
  }
}

@media screen and (max-width: $breakpoint-m) {
  body {
    h1 {
      font-size: 62px !important;
      line-height: 70px !important;
    }
    h2 {
      font-size: 34px !important;
      line-height: 42px !important;
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  body {
    h1 {
      font-size: 38px !important;
      line-height: 42px !important;
    }
    h2 {
      font-size: 24px !important;
      line-height: 32px !important;
    }
  }
}
