.service-overview {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1400px;
  padding: 40px 8.33%;

  &__title {
    font-weight: 500;
    line-height: 56px;
    text-align: center;

    span {
      color: $baseGreen-secondary;
      display: block;
    }
  }

  &__wrapper {
    display: grid;
    gap: 15px 15px;
    grid-gap: 15px 15px;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    grid-auto-rows: 1fr;
    margin-top: 50px;
    width: 100%;

    .service-card {
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      -webkit-transition: all 0.3s;
      background-color: $baseBeige;
      background-position: right -100px top -100px;
      background-repeat: no-repeat;
      background-size: 200px 200px;
      border-radius: 6px;
      box-sizing: border-box;
      display: flex;
      flex-basis: 33.33%;
      flex-direction: column;
      justify-content: space-between;
      min-width: 310px;
      padding: 30px 40px;
      text-decoration: none;
      transition: all 0.3s;

      &:hover {
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -webkit-transition: all 0.3s;
        background-color: #fffcf5;
        background-position: right -120px top -120px;
        background-size: 240px 240px;
        color: $baseGreen-primary;
        transition: all 0.3s;

        &__footer {
          color: $baseGreen-primary;
          transition: 0.3s all ease-in-out;

          .btn-circle {
            border-color: $baseGreen-primary;
            transition: 0.3s all ease-in-out;
            i {
              color: $baseGreen-primary;
              transition: 0.3s all ease-in-out;
            }
          }
        }
      }

      &--onderzoek {
        background-image: url("../../assets/shapes/services/svg_transparant/s_Oetang__serv_research.svg");
      }
      &--strategie {
        background-image: url("../../assets/shapes/services/svg_transparant/s_Oetang__serv_strategy.svg");
      }
      &--product-design {
        background-image: url("../../assets/shapes/services/svg_transparant/s_Oetang__serv_product-design.svg");
      }
      &--contentcreatie {
        background-image: url("../../assets/shapes/services/svg_transparant/s_Oetang__serv_content-creation.svg");
      }
      &--begeleiding {
        background-image: url("../../assets/shapes/services/svg_transparant/s_Oetang__serv_implementation.svg");
      }

      &__header {
        h3 {
          font-size: 30px;
          margin: 0px;
        }
      }

      &__body {
        p {
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          margin: 10px 0px;
        }
      }

      &__footer {
        margin-top: 10px;
        color: $baseGreen-secondary;
      }
    }
  }
}

#workplacehome .only-edu {
    display: none;
}

@media screen and (max-width: 820px) {
  .service-card {
    height: auto !important;
  }

  .service-card__header h3 {
    font-size: 20px !important;
    line-height: 28px;
  }
}

@media screen and (max-width: $breakpoint-s) {
  .service-overview__wrapper {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    .service-card {
      min-width: 100px;
    }
  }
}
