#home {
    background-color: $baseWhite;

    .serviceShape {
        min-width: 150px;
        position: absolute;
        width: 10vw;
        z-index: 0;

        @media screen and (max-width: $breakpoint-m) {
            min-width: 130px;
        }
        @media screen and (max-width: $breakpoint-s) {
            min-width: 100px;
        }
    }
    .shapeResearch {
        left: 35vw;
        top: -70px;
        z-index: 0;

        @media screen and (max-width: $breakpoint-s) {
            top: -50px;
            width: 100px;
            z-index: 9999;
        }
    }
    .shapeStrategy {
        left: -40px;
        top: 8vh;

        @media screen and (max-width: $breakpoint-s) {
            left: -9vw;
        }
    }
    .shapeProductDesign {
        right: -70px;
        top: 5vh;

        @media screen and (max-width: $breakpoint-s) {
            right: -11vw;
        }
    }
    .shapeContentCreation {
        bottom: 30px;
        left: 15vw;

        @media screen and (max-width: $breakpoint-s) {
            left: 7vw;
        }
    }
    .shapeGuidance {
        bottom: -40px;
        right: 20vw;
    }

    @media screen and (max-width: $breakpoint-s) {
        min-width: 100px;
    }

    .sector-choice {
        display: flex;
        flex-direction: row;
        gap: 80px;
        grid-gap: 80px;
        justify-content: space-between;
        margin: auto;
        max-width: 1400px;
        padding: 40px 8.33% !important;
        padding: 60px 0;

        div {
            flex: 1;
            img {
            }
        }

        &__cards {
            display: flex;
            flex-direction: column;
            gap: 20px;
            grid-gap: 20px;
            justify-content: center;

            .sector-choice-card {
                background-color: $baseBeige;
                border-radius: 6px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 40px 60px;
                text-decoration: none;
                transition: all 0.3s;

                &:hover {
                    -webkit-box-shadow: 20px 30px 40px rgba(0, 0, 0, 0.201);
                    box-shadow: 20px 30px 40px rgba(0, 0, 0, 0.201);
                    margin-bottom: 5px;
                    margin-top: -5px;
                    transition: all 0.3s;
                }

                &__body {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: flex-start;

                    h3 {
                        color: $baseGreen-secondary !important;
                        font-size: 30px;
                        line-height: 38px;
                        margin-bottom: 0px;
                        margin-top: 0px;

                        @media screen and (max-width: 1420px) {
                            font-size: 24px;
                            line-height: 32px;
                        }

                        @media screen and (max-width: $breakpoint-l) {
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }

                    p {
                        font-weight: 300;
                    }

                    @media screen and (max-width: $breakpoint-s) {
                        p {
                            display: none;
                        }
                    }

                    @media screen and (max-width: $breakpoint-s) and (min-width: $breakpoint-xs) {
                        p {
                            display: inline;
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }

                &__footer {
                    color: $baseGreen-secondary;
                    justify-self: flex-end !important;
                    max-height: 20px;
                    text-decoration: underline;

                    @media screen and (max-width: $breakpoint-l) {
                        margin-top: 15px;

                        span:last-of-type {
                            display: none;
                        }
                    }

                    @media screen and (max-width: $breakpoint-s) and (min-width: $breakpoint-xs) {
                        display: inline;
                        font-size: 14px;
                        line-height: 20px;

                        span {
                            top: -1px;
                        }

                        span:last-of-type {
                            display: inline;
                            margin-left: -10px;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $breakpoint-l) {
            flex-direction: column;
            gap: 20px;
            grid-gap: 20px;

            .sector-choice__cards {
                flex-direction: row;

                .sector-choice-card {
                    width: 50%;
                }
            }
        }

        @media screen and (max-width: $breakpoint-s) {
            .sector-choice__cards {
                flex-direction: column;
                .sector-choice-card {
                    width: 100%;
                }
            }
        }

        &__photo {
            display: flex;
            justify-content: center;
            
            img {
                width: 100%;
                max-width: 600px;
            }
        }
    }
}
